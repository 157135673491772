import React from "react"

import Layout from "../components/layout"
import jeffGIF from '../gifs/jeff-glasses-gif.gif'

const SecretPage = () => {
  return (
    <Layout>
      <div>
      </div>
        <img src={jeffGIF} alt="Jeff" />
    </Layout>
  )
}


export default SecretPage
